import React, { Component } from 'react'
import ServerConfig from './ServerConfig'
import axios from 'axios'
import { Link } from 'react-router-dom'

export class Quests extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    // on init:
    // if we did not get a pack, look it up from API:
    // built-in: used to detect changes after initialization
    componentDidMount() {
        if (this.props.Quests !== undefined && (this.props.Quests !== null && this.props.Quests.lenth > 0)) {
            // store incoming pack:
            this.setState({ Quests: this.props.Quests })
        } else {
            this._getQuests()
        }
    }

    // self contained code to pull lists from the API:
    // Grab Quest Dataz
    _getQuests = () => {
        let remoteHost = ServerConfig.getInstance()
        let apiendpoint = remoteHost.getAPIEndpoint();
        let questList = undefined
        let quests = undefined
        axios.get(
            apiendpoint + "/items/quest-list",
            { headers: { 'Content-Type': 'application/json' }, timeout: ServerConfig.longApiTimeout }
        ).then(res => {
            if (res.status !== undefined && res.status === 200) {
                questList = res.data;
            } else {
                // THROW AERRORARSARORZ!
                this.props.ReportError("Could not connect to API: " + res.status ? res.status : "Unknown")
            }
        }
        ).then(
            () => {
                if (questList !== undefined) {
                    quests = ['Any']
                    questList.forEach(element => {
                        quests.push(
                            element
                        )
                    });
                }
                this.setState({
                    Quests: quests
                })
            }
        )
            .catch((error) => {
                this.props.ReportError(error)
            });
    }

    render() {
        return this.state.Quests ?
            <div id="questList" className="quests">
                DDO Quests<br/>
                <small>This page is currently under construction, please bear with us as we continue to improve the site.</small>
                {this.state.Quests.map(
                    (incoming, i) =>
                        <div key={i}><Link to={"/quest/" + incoming.replace(/\s/g, "_")}>{incoming}</Link></div>
                )}
            </div>
            :
            <div>Could not locate Adventure Pack information, the list was empty!</div>
    }
}

export default Quests;