import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Colorize from './Colorize'

import SentientJewel from './SentientJewel'

import defaultIcon from '../images/item.png'

export class Examine extends Component {

    _reportItemIssue() {
        // report through property
        this.props.ReportIssue(this.props.DataItem.name);
    }

    _stripTrailingDigits = (floatOrDoubleValue) => {
        if (floatOrDoubleValue !== undefined && floatOrDoubleValue !== null) {
            return floatOrDoubleValue.toFixed(2).toString().match(/^.*?[1-9][1-9]|^[\d][\d]|^.*[\d][\d]/gm)
        }
        else
            return
    }

    // Recolors descriptions to the bright blue color 00BFFF
    colorDescriptions = (uncoloredText) => {
        var coloredParts = uncoloredText.split(/(<(?:rgb|RGB)=#.*?<\/rgb>)/g);
        var allParts = []
        coloredParts.forEach((colorPart, i) => {
            if (colorPart.length > 0 && (colorPart.indexOf("<rgb") >= 0 || colorPart.indexOf("<RGB") >= 0)) {
                var parsedPart = colorPart.split(/<(?:(?:rgb|RGB)=#(.*?))>(.*?)<(?:\/rgb)>/g).filter(Boolean);
                allParts.push(<span key={i} className={"color-" + parsedPart[0]}>{parsedPart[1]}</span>)
            } else {
                // this may remove new lines?
                if (colorPart.length > 0) {
                    allParts.push(colorPart);
                }
            }
        });

        return allParts;
    }

    // colors items between ** white
    enbolden = (stringToEnbolden) => {
        var boldParts = stringToEnbolden.split(/(\*\*.*?\*\*)/g);
        var allParts = []
        boldParts.forEach((boldPart, i) => {
            if (boldPart.length > 0 && (boldPart.indexOf("**") >= 0)) {
                var parsedPart = boldPart.split(/(?:\*\*(.*?)\*\*)/g).filter(Boolean);
                allParts.push(<span key={i} className="enbolden">{parsedPart}</span>)
            } else {
                // this may remove new lines?
                if (boldPart.length > 0) {
                    allParts.push(<span key={i}>{boldPart}</span>);
                }
            }
        });
        return allParts;
    }

    getCharges = (item) => {
        if (item.charges || item.maxCharges) {
            return (
                <div className="panel-main-recharges">
                    {item.charges < item.maxCharges ? item.charges : item.maxCharges} / {item.maxCharges} Charges<span></span>
                    {item.recharge ? <span>(Recharged/Day: {item.recharge})</span> : null}
                </div>);
        }
    }

    // * This is a hack used to place the clicky on the top or bottom
    // * Takes a Clicky, casterLevel, Type, and (Wand) isEternal
    getClicky = (clickySpell, casterLevel, itemType, isEternal) => {
        return (
            clickySpell ?
                <div className="panel-main-clicky">
                    <div className="panel-main-spell">
                        <div className="panel-main-spell-icon">
                            <img src={`${clickySpell.iconSource}`} alt="Clicky" />
                        </div>
                        <div className="panel-main-spell-text">
                            {clickySpell.spellName ?
                                <h1>
                                    {clickySpell.spellName}
                                </h1>
                                : null}
                            <div className="panel-main-spell-right">
                                {clickySpell.validTargets ?
                                    <div className="panel-main-spell-targets">
                                        Target: {clickySpell.validTargets.map((validTarget, key) => {
                                        return <span key={key}>{validTarget}</span>
                                    })}
                                    </div> : null}
                                {clickySpell.duration ?
                                    <div className="panel-main-spell-duration">
                                        Duration: {clickySpell.duration}
                                    </div> : null}
                                {clickySpell.magicSchool ?
                                    <div className="panel-main-spell-school">
                                        School: {clickySpell.magicSchool}
                                    </div> : null}
                                {clickySpell.spellResistance !== undefined ?
                                    <div className="panel-main-spell-spell-resist">
                                        Spell Resistance: {clickySpell.spellResistance ? "Yes" : "No"}
                                    </div> : null}
                                {clickySpell.spellDescription ?
                                    <div className="panel-main-spell-description">
                                        {clickySpell.spellDescription.split('\\n').map((item, key) => {
                                            return <span key={key}>{Colorize(item)}<br /></span>
                                        })}
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>

                    {casterLevel && (itemType !== undefined && (itemType !== "Runearm")) ?
                        <div className="panel-main-caster-level">Caster Level: {casterLevel}</div>
                        : null}
                </div>
                : null
        )
    }

    render() {
        const { absoluteMinimumLevel, acceptsSentience, adamantineBodyArmorBonus,
            armorBonus, armorSubType, armorType, armorTypeString, attackPenalty,
            attackStats, baseDamageDice, baseDamageRating,
            binding, casterLevel, clickySpell, damageReduction, damageStats,
            description, durability, effects, equipsTo, exclusive, featRequired,
            hardness, iconSource, ingredientType, isMinorArtifact,
            isDroppedOnDeath, isDroppedOnLeavingAdventure, material, maxDexBonus, minimumLevel,
            mithralBodyArmorBonus, name, noUmdCheckList, classRequiredList,
            petRaceAbsolutelyRequired, proficiency, raceAbsolutelyExcluded, raceAbsolutelyRequired,
            absoluteRaceRequired, setBonus1Description, setBonus1Name, setBonus2Description,
            setBonus2Name, shieldBonus, shieldType, skillCheckPenalty, maxStackSize,
            spellFailure, usageDifficulty, weaponType, actsAsStaff, uniqueItemCapacity,
            weaponTypeString, itemType, weight, platValue, goldValue,
            silverValue, copperValue, damageString, criticalRollString, isEternal, internalId,
            questDifficulty, foundIn, displayName, sentienceInfo, augmentSlots
        } = this.props.DataItem

        const { hideEffectDescriptions } = this.props;

        // static character value:
        var plusStat = 9;

        return (
            <div id="examine" className="examinate">
                <div id={this.props.style}>
                    <div className="panel-container">
                        <div className="panel-left-sidebar">
                            <div className="panel-left-top-content">
                                <div className="panel-left-top-image">
                                </div>
                            </div>
                            <div className="panel-left-mid-content">
                                <div className="panel-left-sidebar-image">
                                    <div className="panel-left-fangle">
                                    </div>
                                    <div className="panel-left-sidebar-bottom-image">
                                    </div>
                                </div>
                            </div>
                            <div className="panel-left-bottom-content">
                                <div className="panel-left-bottom-image">
                                </div>
                            </div>
                        </div>
                        <div className="panel-middle">
                            <div className="panel-middle-header">
                                <div className="panel-middle-header-image"></div>
                            </div>
                            <div className="panel-middle-body-container">
                                <div className="panel-middle-top-shadow">
                                    <div className="panel-middle-body-content">

                                        <div className="panel-main-row-one">
                                            <div className="panel-main-icon">
                                                <img src={iconSource ? iconSource : defaultIcon} alt={name} ></img>
                                            </div>
                                            <div className="panel-item">
                                                {name ? <div className="panel-item-name">{name}</div> : "No name"}
                                                <div className="type-and-diff-wrapper">
                                                    {questDifficulty ?
                                                        <div className="left-flex-spacer">
                                                            {questDifficulty}
                                                        </div>
                                                        : null
                                                    }
                                                    {weaponType !== undefined ?
                                                        (weaponTypeString) ?
                                                            <div className="panel-main-weapon-type">{weaponTypeString}</div> :
                                                            null
                                                        : null}
                                                    {armorType !== undefined && armorType !== "Undef" ?
                                                        (armorType) ?
                                                            <div className="panel-main-armor-type">{armorTypeString ? armorTypeString : armorType}</div> :
                                                            null
                                                        : null}
                                                    {shieldType !== undefined && shieldType !== "Undef" ?
                                                        (shieldType) ?
                                                            <div className="panel-main-shield-type">{shieldType ? shieldType : shieldType}</div> :
                                                            null
                                                        : null}
                                                    {questDifficulty ?
                                                        <div className={"panel-quest-difficulty " + questDifficulty}>
                                                            {questDifficulty}
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                                {isMinorArtifact ?
                                                    <div className="panel-main-artifact">Minor Artifact: (XP : 0)</div>
                                                    : null}
                                                {acceptsSentience ?
                                                    <div className="panel-main-accept-sentience">Accepts Sentience</div>
                                                    : null}
                                            </div>
                                        </div>
                                        <div className="panel-main-row-two">
                                            {acceptsSentience ?
                                                <div className="panel-main-spacer-after-name">

                                                </div>
                                                : null}
                                            {equipsTo != null && equipsTo.length > 0 ?
                                                <div className="panel-main-equips-to">
                                                    <div className="panel-main-equips-wrap-right">
                                                        <p>Equips to:</p>
                                                        {equipsTo.map(
                                                            (slotOrItem, i) =>
                                                                <div key={i}> {slotOrItem}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                : null}

                                            {ingredientType ? <div className="panel-main-ingredient-type">Ingredient Type: {ingredientType}</div> : null}
                                            {proficiency ? <div className="panel-main-proficiency">{proficiency}</div> : null}
                                            {usageDifficulty ? <div className="panel-main-umd-diff"> UMD Difficulty: {usageDifficulty}</div> : null}
                                            {noUmdCheckList != null && noUmdCheckList.length > 0 ?
                                                <div className="panel-main-umd-list"> No UMD check for: {noUmdCheckList.map(
                                                    (className, i) => <div key={i}>{className}</div> )}
                                                </div>
                                                 : null}
                                            {raceAbsolutelyExcluded ? <div className="panel-main-races-excluded">Race Absolutely Excluded: {raceAbsolutelyExcluded}</div> : null}
                                            {absoluteRaceRequired ?
                                                <div className="panel-main-races-required">
                                                    Race Absolutely Required: {absoluteRaceRequired}
                                                </div> : null}
                                            {raceAbsolutelyRequired ?
                                                <div className="panel-main-races-required">
                                                    Race Absolutely Required: {raceAbsolutelyRequired}
                                                </div> : null}
                                            {petRaceAbsolutelyRequired ? <div className="panel-main-pet-races-required">Pet Race Absolutely Required: {petRaceAbsolutelyRequired}</div> : null}
                                            {armorType && (armorSubType === undefined || armorSubType !== "Docent") ?
                                                <div className="panel-main-armor">
                                                    {maxDexBonus ?
                                                        <div className="panel-main-armor-bonus-dex-max">
                                                            <span>Max Bonus w/ Dex:</span> {armorBonus + maxDexBonus}
                                                        </div> : null}
                                                    {armorBonus ?
                                                        <div className="panel-main-armor-bonus">
                                                            <span>Armor Bonus:</span> {armorBonus}
                                                        </div>
                                                        : null}
                                                    {maxDexBonus ?
                                                        <div className="panel-main-armor-bonus-dex">
                                                            <span>Max Dex Bonus</span> {maxDexBonus}
                                                        </div> : null}
                                                    {skillCheckPenalty ?
                                                        <div className="panel-main-armor-penalty">
                                                            <span>Armor Check Penalty:</span> {skillCheckPenalty}
                                                        </div> : null}
                                                    {spellFailure ?
                                                        <div className="panel-main-spell-failure">
                                                            <span>Spell Failure:</span> {spellFailure}%
                                                </div> : null}
                                                </div>
                                                : null}
                                            {minimumLevel ?
                                                <div className="panel-main-min-level">
                                                    Minimum Level: {minimumLevel}
                                                </div>
                                                : null}
                                            {absoluteMinimumLevel ?
                                                <div className="panel-main-absolute-min-level">
                                                    Absolute Minimum Level: {absoluteMinimumLevel}
                                                </div>
                                                : null}
                                            {featRequired ?
                                                <div className="panel-main-feat-required">
                                                    Feat Required: {featRequired}
                                                </div>
                                                : null}
                                            {classRequiredList ?
                                                <div className="panel-main-required-class-list">
                                                    Class Required: {classRequiredList.map((classR, i) => {
                                                    return (
                                                        <span key={classR + i}>{classR}{(classRequiredList.length - (i + 1) !== 0) ? ", " : null}</span>
                                                    )
                                                })}
                                                </div>
                                                : null}
                                            {isDroppedOnLeavingAdventure ?
                                                <div className="panel-main-dropped-on-leaving-adventure">
                                                    Drops on Leaving Adventure
                                            </div>
                                                : null}
                                            {isDroppedOnDeath ?
                                                <div className="panel-main-dropped-on-death">
                                                    Drops on Death
                                        </div>
                                                : null}
                                            {binding ?
                                                <div className="panel-main-binding">
                                                    {binding}
                                                </div>
                                                : null}
                                            {maxStackSize ?
                                                <div className="panel-main-max-stacksize">
                                                    Max stack size of contents: {maxStackSize}
                                                </div>
                                                : null}
                                            {uniqueItemCapacity ?
                                                <div className="panel-main-unique-item-capacity">
                                                    Unique item capacity: 0 / {uniqueItemCapacity}
                                                </div>
                                                : null}
                                            {exclusive ?
                                                <div className="panel-main-exclusive">
                                                    Exclusive</div>
                                                : null}
                                            {armorSubType !== undefined && armorSubType === "Docent" ?
                                                <div className="panel-main-armor-docent">
                                                    <span>Armor Bonus:</span>
                                                    {adamantineBodyArmorBonus ?
                                                        <div className="panel-main-armor-adamantine">Adamantine Body: +{adamantineBodyArmorBonus}</div>
                                                        : null}
                                                    {mithralBodyArmorBonus ?
                                                        <div className="panel-main-armor-mithral">Mithral Body: +{mithralBodyArmorBonus}</div>
                                                        : null}
                                                </div>
                                                : null}
                                            {shieldType ?
                                                <div className="panel-main-shield">
                                                    {shieldBonus ?
                                                        <div className="panel-main-shield-bonus">
                                                            <span>Shield Bonus:</span> +{shieldBonus}
                                                        </div>
                                                        : null}
                                                    {maxDexBonus ?
                                                        <div className="panel-main-shield-bonus-dex">
                                                            <span>Max Dex Bonus:</span> {maxDexBonus}
                                                        </div> : null}
                                                    {damageReduction ?
                                                        <div className="panel-main-shield-damage-reduction">
                                                            <span>Damage Reduction (DR):</span> {damageReduction}
                                                        </div> : null}
                                                    {skillCheckPenalty ?
                                                        <div className="panel-main-shield-penalty">
                                                            <span>Armor Check Penalty:</span> {skillCheckPenalty}
                                                        </div> : null}
                                                    {spellFailure ?
                                                        <div className="panel-main-spell-failure">
                                                            <span>Spell Failure:</span> {spellFailure}%
                                                </div> : null}
                                                    {attackPenalty ?
                                                        <div className="panel-main-attack-penalty">
                                                            <span>Attack Penalty:</span> {attackPenalty}
                                                        </div> : null}
                                                </div>
                                                : null}
                                            {baseDamageRating || baseDamageDice ?
                                                <div className="panel-main-weapon">
                                                    {baseDamageRating ?
                                                        <div className="panel-main-base-damage-rating">
                                                            <span>
                                                                Base Damage Rating:</span>
                                                            <div className="panel-main-base-damage-rating-value">{baseDamageRating.toString().indexOf(".") > 0 ?
                                                                baseDamageRating.toFixed(2)
                                                                : baseDamageRating}
                                                            </div>
                                                        </div>
                                                        : null}
                                                    {damageString ?
                                                        <div className="panel-main-weapon-damage">
                                                            {this.enbolden("**Damage:**" + damageString)}
                                                        </div>
                                                        : null}
                                                    {criticalRollString ?
                                                        <div className="panel-main-weapon-crit-multiplier">
                                                            <span>Critical Roll:</span>{criticalRollString}
                                                        </div>
                                                        : null}
                                                    {attackStats ?
                                                        <div className="panel-main-weapon-attack-mod">
                                                            <span>Attack Mod:</span> {attackStats[0]} (+{plusStat}) {attackStats.length > 1 ?
                                                                <div>
                                                                    ({attackStats.map((hit, i) => (<span key={i}>{hit}</span>))})
                                                        </div>
                                                                : null}
                                                        </div>
                                                        : null}
                                                    {damageStats ?
                                                        <div className="panel-main-weapon-damage-mod">
                                                            <span>Damage Mod: </span>{damageStats[0]} (+{plusStat}) {damageStats.length > 1 ?
                                                                <div>
                                                                    ({damageStats.map((hit, i) => (<span key={i}>{hit}</span>))})
                                                        </div>
                                                                : null}
                                                        </div>
                                                        : null}
                                                </div>
                                                : null}
                                            {/* Runearm, Armor, Shield, Wand, Orb, Jewelry, Clothing */}
                                            {actsAsStaff === undefined && itemType !== "Weapon" ?
                                                (
                                                    <div>{this.getClicky(clickySpell, casterLevel, itemType, isEternal)}{this.getCharges(this.props.DataItem)}</div>
                                                )
                                                : null}
                                            {effects.length > 0 ?
                                                <div className="panel-main-effect-list">
                                                    {effects.map(
                                                        (effect, i) =>
                                                            <div className="panel-main-effect" key={i}>
                                                                <span className="panel-main-effect-list-name">{Colorize(effect.name)}{hideEffectDescriptions === false ? ": " : null}</span>
                                                                {hideEffectDescriptions === false ?
                                                                    effect.description.split('\\n').map((text, br) => {
                                                                        return <span key={br}>{this.colorDescriptions(text)}<br /></span>
                                                                    })
                                                                    : null
                                                                }
                                                                {effect.subEffects !== undefined && effect.subEffects.length > 1 ?
                                                                    effect.subEffects.map((subEffect, s) =>
                                                                        subEffect.name === subEffect.description ?
                                                                            <div className="panel-sub-effect" key={s}>{subEffect.name}
                                                                                {subEffect.percentChance ?
                                                                                    <span className="engolden">{" (" + this._stripTrailingDigits(subEffect.percentChance) + "%)"}</span>
                                                                                    : null}
                                                                            </div>
                                                                            :
                                                                            <div className="panel-sub-effect" key={s}>{subEffect.name}
                                                                                {subEffect.percentChance ?
                                                                                    <span className="engolden">
                                                                                        {" (" + this._stripTrailingDigits(subEffect.percentChance) + "%)"}
                                                                                    </span>
                                                                                    : null}
                                                                                {hideEffectDescriptions === false ? <span className="panel-sub-effect-description">: {subEffect.description}</span> : null}
                                                                                {subEffect.subEffects.length > 0 ?
                                                                                    subEffect.subEffects.map((superSubEffect, e) => {
                                                                                        return <div className="panel-sub-effect" key={e}>{superSubEffect.name}{hideEffectDescriptions === false ? <span className="panel-sub-effect-description">:  {superSubEffect.description}</span> : null}</div>
                                                                                    })
                                                                                    : null}
                                                                            </div>
                                                                    )
                                                                    : null}
                                                            </div>
                                                    )}
                                                </div>
                                                : null}
                                            {actsAsStaff || itemType === "Weapon" ?
                                                (
                                                    <div>{this.getClicky(clickySpell, casterLevel, itemType, isEternal)}{this.getCharges(this.props.DataItem)}</div>
                                                )
                                                : null}
                                            {augmentSlots ?
                                                augmentSlots.map((item, i) => {
                                                    //{name, color, empty, description}
                                                    return <div key={i}><span className={"color-" + item.color}>{item.name}</span>: {item.empty ? "Empty" : "Unknown Augment"}<br /></div>
                                                }) : null}
                                            {setBonus1Description ?
                                                <div className="panel-main-set-bonus-one">
                                                    {setBonus1Name ?
                                                        <div className="panel-main-set-bonus-one-name">{setBonus1Name}{!hideEffectDescriptions ? ":" : null} </div>
                                                        : null}
                                                    {!hideEffectDescriptions ? <div>{setBonus1Description ? setBonus1Description.map((item, key) => {
                                                        return <div key={key}>• <span>{item.split('\\n').map((item, br) => {
                                                            return <span key={br}>{item}</span>
                                                        })}<br /></span></div>
                                                    }) : null}
                                                    </div> : null}
                                                </div> : null}
                                            {setBonus2Description ?
                                                <div className="panel-main-set-bonus-two">
                                                    {setBonus2Name ?
                                                        <div className="panel-main-set-bonus-two-name">{setBonus2Name}{!hideEffectDescriptions ? ":" : null} </div>
                                                        : null}
                                                    {!hideEffectDescriptions ? <div>{setBonus2Description ? setBonus2Description.map((item, key) => {
                                                        return <div key={key}>•<span key={key}>{item.split('\\n').map((item, br) => {
                                                            return <span key={br}>{item}</span>
                                                        })}<br /></span></div>
                                                    }) : null}
                                                    </div> : null}
                                                </div> : null}
                                        </div>
                                        <div className="panel-main-row-three">
                                            {durability && (durability !== 4294967295) ?
                                                <div className="panel-main-durability">
                                                    <div className="panel-main-durability-text">Durability: {durability} / {durability}<br />
                                                        {material ? material : "Unknown material"} (Hardness: {hardness ? hardness : 0})</div>
                                                </div>
                                                : null}
                                        </div>
                                        <div className="panel-main-row-four">
                                            {description ? <div className="panel-main-description">{description.split('\\n').map((item, key) => {
                                                return <span key={key}>{Colorize(item)}<br /></span>
                                            })}</div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-middle-footer">
                                <div className="panel-middle-footer-image">
                                    <div className="panel-middle-footer-data">
                                        <div className="panel-middle-coinage-value">
                                            {platValue || goldValue || silverValue || copperValue ?
                                                <div className="panel-middle-coinage-container">
                                                    Base Value:
                                                {platValue ?
                                                        <div className="panel-middle-platinum-coinage">
                                                            {platValue.toLocaleString('en', { useGrouping: true })}
                                                        </div> : null}
                                                    {goldValue ?
                                                        <div className="panel-middle-gold-coinage">
                                                            {goldValue.toLocaleString('en', { useGrouping: true })}
                                                        </div>
                                                        : null}
                                                    {silverValue ?
                                                        <div className="panel-middle-silver-coinage">
                                                            {silverValue.toLocaleString('en', { useGrouping: true })}
                                                        </div>
                                                        : null}
                                                    {copperValue ?
                                                        <div className="panel-middle-copper-coinage">
                                                            {copperValue.toLocaleString('en', { useGrouping: true })}
                                                        </div> : null}
                                                </div>
                                                : null}
                                        </div>
                                        <div className="panel-middle-weight">
                                            {weight ?
                                                ((weight.toString().indexOf(".") > 0) ?
                                                    weight.toFixed(2)
                                                    : weight)
                                                + " lbs"
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="panel-right-sidebar">
                            <div className="panel-right-top-content">
                                <div className="panel-right-top-image">
                                </div>
                            </div>
                            <div className="panel-right-mid-content">
                                <div className="panel-right-sidebar-image">
                                    <div className="panel-right-fangle">
                                    </div>
                                    <div className="panel-right-sidebar-bottom-image">
                                    </div>
                                </div>
                            </div>
                            <div className="panel-right-bottom-content">
                                <div className="panel-right-bottom-image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-container">
                    <div className="extra-info">
                        <div><button className="examination-button" onClick={this.props.toggleHideEffectDescriptions}>{!hideEffectDescriptions ? "Hide" : "Show"} Detailed Descriptions</button></div>
                        <div>Additional information for {displayName ? displayName : name ? name : null}</div>
                        <div className="extra-quest-info">
                            {absoluteRaceRequired ?
                                <div>
                                    <a target="_blank" rel="noopener noreferrer" href="https://ddowiki.com/page/Advance_to_level_15">This is Iconic level 15 Starter-Gear</a><br />
                                    Obtained by starting as Iconic race: <a target="_blank" rel="noopener noreferrer" href={"https://ddowiki.com/page/" + absoluteRaceRequired}><span className="engolden">{absoluteRaceRequired}</span></a>
                                </div>
                                : null}
                            {foundIn && foundIn.length > 0 ?
                                <div>Found in the following quest(s)
                                    {foundIn.map((found, s) => {
                                    return <div key={s}>
                                        <ul>
                                            <li>
                                                <Link to={"/quest/" + encodeURI(found.questName.replace(/\s/g, '_'))}>{found.questName}</Link>, (<a target="_blank" rel="noopener noreferrer" href={"https://ddowiki.com/page/" + found.questName}>DDOWiki</a>), {found.chestName}<br />
                                                {found.adventurePack ? <span className="panel-extra-adventure-pack">Pack: <Link to={"/adventure-pack/" + found.adventurePack.replace(/\s/g, '_')}>{found.adventurePack}</Link> </span> : null}
                                                {found.dropRates !== undefined ?
                                                    <ul><span data-toggle="tooltip" title="Chances of getting this item, not getting a named item in general.">Drop Rates</span>
                                                        {Object.keys(found.dropRates).map(rate => {
                                                            return <li key={rate}>{rate.replace("_", " ")}: <span className="engolden">{this._stripTrailingDigits(found.dropRates[rate] * 100)}%</span></li>
                                                        })}
                                                    </ul>
                                                    : null}
                                                {found.mythicBonuses && found.mythicBonuses.length > 0 ?
                                                    <ul><span data-toggle="tooltip" title="Chances assume you got the item.">Possible Mythic Bonuses</span>
                                                        {found.mythicBonuses.map((bonus, i) => {
                                                            return <li key={i}>{bonus.bonusName}
                                                                <ul>
                                                                    {Object.keys(bonus.dropRates).map(b => {
                                                                        return <li key={b}>{b.replace("_", " ")}: <span className="engolden">{this._stripTrailingDigits(bonus.dropRates[b] * 100)}%</span></li>
                                                                    })}
                                                                </ul>
                                                            </li>
                                                        })}
                                                    </ul>
                                                    : null}
                                            </li>
                                        </ul>
                                    </div>
                                })}
                                </div>
                                : null
                            }
                            {(sentienceInfo !== undefined && sentienceInfo !== null) ?
                                <SentientJewel {...this.props} sentience={sentienceInfo} ReportIssue={this.props.ReportIssue} />
                                : null}
                        </div>
                        <div>
                            <a target="_blank" rel="noopener noreferrer" href={"https://ddowiki.com/page/Item:" + name.replace("?", "%3F")}>DDOWiki</a> - <a target="_blank" rel="noopener noreferrer" href={"https://ddocompendium.com/w/" + name.replace("?", "%3F")}>DDO Compendium</a> - <a target="_blank" rel="noopener noreferrer" href={"https://www.google.com/search?q=" + name.replace("?", "%3F") + "+DDO"}>Google</a>
                        </div>
                        {internalId ? <div>{displayName} {"0x" + internalId.toString(16).toUpperCase() + " : " + internalId}</div> : null}
                        <div>
                            <button className="examination-button" onClick={this._reportItemIssue.bind(this)}>Report an issue with this item</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

// PropTypes
Examine.propTypes = {
    DataItem: PropTypes.object.isRequired,
    ReportIssue: PropTypes.func.isRequired,
    ReportError: PropTypes.func.isRequired,
    hideEffectDescriptions: PropTypes.bool.isRequired,
    toggleHideEffectDescriptions: PropTypes.func.isRequired
}


export default Examine;