import React, { Component } from 'react'
import PropTypes from 'prop-types'

import axios from 'axios'
import ServerConfig from './ServerConfig.js'

import Select from 'react-select';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';

import '../css/GearFilter.css'
import 'rc-slider/assets/index.css';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
        <Tooltip
            prefixCls="rc-slider-tooltip"
            overlay={value}
            visible={dragging}
            placement="top"
            key={index}
        >
            <Handle value={value} {...restProps} />
        </Tooltip>
    );
};

// Static choices for the drop down fields, this is all done by hand so add/remove fields that are missing or errornouse, when they are found:

const options = [
    { value: 'Any', label: 'Any' },
    { value: 'Armor', label: 'Armor' },
    { value: 'Augment', label: 'Augment' },
    { value: 'Clothing', label: 'Clothing' },
    //{ value: 'Ingredient', label: 'Ingredient' },
    { value: 'Jewelry', label: 'Jewelry' },
    { value: 'Runearm', label: 'Runearm' },
    { value: 'Shield', label: 'Shield' },
    { value: 'BagOfSingletons', label: 'Quiver' },
    { value: 'Wand', label: 'Wand' },
    { value: 'Weapon', label: 'Weapon' }
]

const armorOptions = [
    { value: 'Any', label: 'Any' },
    { value: 'Breastplate', label: 'Breastplate' },
    { value: 'Brigandine', label: 'Brigandine' },
    { value: 'Chain Shirt', label: 'Chain Shirt' },
    { value: 'Chainmail', label: 'Chainmail' },
    { value: 'Chainrobe', label: 'Chainrobe' },
    { value: 'Docent', label: 'Docent' },
    { value: 'Full Plate', label: 'Full Plate' },
    { value: 'Half Plate', label: 'Half Plate' },
    { value: 'Hide', label: 'Hide' },
    { value: 'Leather', label: 'Leather' },
    { value: 'Padded', label: 'Padded' },
    { value: 'Scalemail', label: 'Scalemail' },
    { value: 'Splint Mail', label: 'Splint Mail' },
]

const shieldOptions = [
    { value: 'Any', label: 'Any' },
    { value: 'Buckler', label: 'Buckler' },
    { value: 'Large Shield', label: 'Large' },
    { value: 'Orb', label: 'Orb' },
    { value: 'Small Shield', label: 'Small' },
    { value: 'Tower Shield', label: 'Tower' }
]

const weaponOptions = [
    { value: 'Any', label: 'Any' },
    { value: 'Rapier', label: 'Rapier' },
    { value: 'Longsword', label: 'Longsword' },
    { value: 'Shortbow', label: 'Shortbow' },
    { value: 'Longbow', label: 'Longbow' },
    { value: 'Dagger', label: 'Dagger' },
    { value: 'Scimitar', label: 'Scimitar' },
    { value: 'Repeating Light Crossbow', label: 'Repeating Light Crossbow' },
    { value: 'Shuriken', label: 'Shuriken' },
    { value: 'Greatsword', label: 'Greatsword' },
    { value: 'Javelin', label: 'Javelin' },
    { value: 'Bastard Sword', label: 'Bastard Sword' },
    { value: 'Shortsword', label: 'Shortsword' },
    { value: 'Light Crossbow', label: 'Light Crossbow' },
    { value: 'Repeating Heavy Crossbow', label: 'Repeating Heavy Crossbow' },
    { value: 'Heavy Crossbow', label: 'Heavy Crossbow' },
    { value: 'Great Crossbow', label: 'Great Crossbow' },
    { value: 'Greataxe', label: 'Greataxe' },
    { value: 'Heavy Mace', label: 'Heavy Mace' },
    { value: 'Battle Axe', label: 'Battleaxe' },
    { value: 'Hand Crossbow', label: 'Hand Crossbow' },
    { value: 'Unarmed', label: 'Unarmed' },
    { value: 'Warhammer', label: 'Warhammer' },
    { value: 'Club', label: 'Club' },
    { value: 'Quarterstaff', label: 'Quarterstaff' },
    { value: 'Full Blade', label: 'Full Blade' },
    { value: 'Light Hammer', label: 'Light Hammer' },
    { value: 'Kukri', label: 'Kukri' },
    { value: 'Sickle', label: 'Sickle' },
    { value: 'Kama', label: 'Kama' },
    { value: 'Khopesh', label: 'Khopesh' },
    { value: 'Heavy Pick', label: 'Heavy Pick' },
    { value: 'Greatclub', label: 'Greatclub' },
    { value: 'Dwarven War Axe', label: 'Dwarven War Axe' },
    { value: 'Throwing Axe', label: 'Throwing Axe' },
    { value: 'Light Mace', label: 'Lightmace' },
    { value: 'Maul', label: 'Maul' },
    { value: 'Falchion', label: 'Falchion' },
    { value: 'Morningstar', label: 'Morningstar' },
    { value: 'Throwing Dagger', label: 'Throwing Dagger' },
    { value: 'Light Pick', label: 'Light Pick' },
    { value: 'Handaxe', label: 'Handaxe' },
    { value: 'Throwing Hammer', label: 'Throwing Hammer' },
    { value: 'Chain', label: 'Chain' },
    { value: 'Handwraps', label: 'Handwraps' }
]

const equipsToOptions = [
    { value: 'Any', label: 'Any' },
    { value: 'Head', label: 'Head' },
    { value: 'Eyes', label: 'Eyes' },
    { value: 'Cloak', label: 'Cloak' },
    { value: 'Finger1', label: 'First Finger' },
    // not needed:{ value: 'Finger2', label: 'Second Finger' },
    { value: 'Wrists', label: 'Wrists' },
    { value: 'Trinket', label: 'Trinket' },
    { value: 'Neck', label: 'Neck' },
    { value: 'Hands', label: 'Hands' },
    { value: 'Waist', label: 'Waist' },
    { value: 'Feet', label: 'Feet' },
    { value: 'Weapon1', label: 'Main Hand' },
    { value: 'Weapon2', label: 'Off Hand' },
    { value: 'Ammo', label: 'Ammo' },
    { value: 'Quiver', label: 'Quiver' },
    { value: 'Runearm', label: 'Runearm' },
    { value: 'PetUpgrade', label: 'Pet Upgrade' },
    { value: 'PetEquipment', label: 'Pet Equipment' },
    { value: 'Cosmetic_Cloak', label: 'Cosmetic Cloak' },
    { value: 'Cosmetic_Weapon1', label: 'Cosmetic Main Hand' },
    { value: 'Cosmetic_Weapon2', label: 'Cosmetic Off Hand' },
    { value: 'Cosmetic_Armor', label: 'Cosmetic Armor' },
    { value: 'Cosmetic_Helmet', label: 'Cosmetic Helmet' },
]

//** Provides filter options for the Search menu. */
class GearFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subtypeOptions: null,
            selectedItemType: null,
            selectedItemSubType: null,
            selectedEquipsToSlot: null,
            selectedQuest: null,
            selectedAdventurePack: null,
            // this field is required to be an array due to the slider component:
            selectedMinMaxLevel: [0, 30],
            Quests: [],
            AdventurePacks: []
        };
    }

    // built-in: used to detect changes after initialization
    componentDidMount() {
        if (this.state.Quests === undefined || this.state.Quests.length <= 0) {
            this._getQuests()
        }
        if (this.state.AdventurePacks === undefined || this.state.AdventurePacks.length <= 0) {
            this._getAdventurePacks()
        }
    }

    // built-in: used to detect changes after initialization
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.resetCounter !== nextProps.resetCounter) {
            this._clearFields()
        }

        if (this.state !== nextState) {
            return true;
        }

        return false;
    }

    // Clears all of the filter fields:
    _clearFields() {
        this.setState(
            {
                selectedQuest: null,
                subtypeOptions: null,
                selectedItemType: null,
                selectedItemSubType: null,
                selectedEquipsToSlot: null,
                selectedAdventurePack: null,
                selectedMinMaxLevel: [0, 30]
            });
    };

    _setQuest = (quest) => {
        var questSelection = quest.replace(/_/gi, ' ')
        this.setState(
            {
                selectedQuest: { value: questSelection, label: questSelection },
                subtypeOptions: null,
                selectedItemType: null,
                selectedItemSubType: null,
                selectedEquipsToSlot: null,
                selectedAdventurePack: null,
                selectedMinMaxLevel: [0, 30]
            });
    };

    _setAdventurePack = (pack) => {
        var questPack = pack.replace(/_/gi, ' ')
        this.setState(
            {
                selectedQuest: null,
                subtypeOptions: null,
                selectedItemType: null,
                selectedItemSubType: null,
                selectedEquipsToSlot: null,
                selectedAdventurePack: { value: questPack, label: questPack },
                selectedMinMaxLevel: [0, 30]
            });
    };

    _getAdventurePacks = () => {
        let remoteHost = ServerConfig.getInstance()
        let apiendpoint = remoteHost.getAPIEndpoint();
        let adventurePackList = undefined
        let adventurePack = undefined
        axios.get(
            apiendpoint + "/items/adventure-pack-list",
            { headers: { 'Content-Type': 'application/json' }, timeout: ServerConfig.longApiTimeout }
        ).then(res => {
            if (res.status !== undefined && res.status === 200) {
                adventurePackList = res.data;
            } else {
                // THROW AERRORARSARORZ!
                this.props.ReportError("Could not connect to API: " + res.status ? res.status : "Unknown")
            }
        }
        ).then(
            () => {
                if (adventurePackList !== undefined) {
                    adventurePack = [{ value: 'Any', label: 'Any' }]
                    adventurePackList.forEach(element => {
                        adventurePack.push(
                            { value: element, label: element }
                        )
                    });
                }
                this.setState({
                    AdventurePacks: adventurePack
                })
            }
        )
            .catch((error) => {
                this.props.ReportError(error)
            });
    }

    // Grab Quest Dataz
    _getQuests = () => {
        let remoteHost = ServerConfig.getInstance()
        let apiendpoint = remoteHost.getAPIEndpoint();
        let questList = undefined
        let quests = undefined
        axios.get(
            apiendpoint + "/items/quest-list",
            { headers: { 'Content-Type': 'application/json' }, timeout: ServerConfig.longApiTimeout }
        ).then(res => {
            if (res.status !== undefined && res.status === 200) {

                questList = res.data;

            } else {
                // THROW AERRORARSARORZ!
                this.props.ReportError("Could not connect to API: " + res.status ? res.status : "Unknown")
            }
        }
        ).then(
            () => {
                if (questList !== undefined) {
                    quests = [{ value: 'Any', label: 'Any' }]
                    questList.forEach(element => {
                        quests.push(
                            { value: element, label: element }
                        )
                    });
                }
                this.setState({
                    Quests: quests
                })
            }
        )
            .catch((error) => {
                this.props.ReportError(error)
            });
    }

    // Updates the triggerfields and tells the App to retrieve a new list
    _triggerUpdate() {
        const { selectedMinMaxLevel, selectedItemType, selectedItemSubType, selectedEquipsToSlot, selectedQuest, selectedAdventurePack } = this.state;

        var filterOptions = {};

        filterOptions.selectedMinMaxLevel = selectedMinMaxLevel

        if (selectedItemType !== null && selectedItemType.value !== "Any") {
            filterOptions.selectedItemType = selectedItemType.value
        }
        if (selectedItemSubType !== null && selectedItemSubType !== "Any") {
            filterOptions.selectedItemSubType = selectedItemSubType.value
        }
        if (selectedEquipsToSlot !== null && selectedEquipsToSlot.value !== "Any") {
            filterOptions.selectedEquipsToSlot = this._getSearchLocation(selectedEquipsToSlot.value)
        }
        if (selectedQuest !== null && selectedQuest.value !== "Any") {
            filterOptions.selectedQuest = selectedQuest.value
        }
        if (selectedAdventurePack !== null && selectedAdventurePack.value !== "Any") {
            filterOptions.selectedAdventurePack = selectedAdventurePack.value
        }

        // This set's the name of the data object that needs to match in the main app:
        var newFilters = { filters: filterOptions }

        // execute parent function to alert the main app of changes in the filters
        this.props.onChangeHandler(
            newFilters
        )
    }

    // select type, unselect subtype and equiptoslot
    _selectType = selectedItemType => {
        this.setState(
            {
                selectedItemType,
                selectedItemSubType: null,
                selectedEquipsToSlot: null,
                selectedQuest: null,
                selectedAdventurePack: null,
            },
            () => {
                this._populateSubtype(selectedItemType)
                this._triggerUpdate()
            }
        )
    };

    // select subtype, unselect equiptoslot
    _selectSubType = selectedItemSubType => {
        this.setState(
            {
                selectedItemSubType,
                selectedEquipsToSlot: null,
                selectedQuest: null,
                selectedAdventurePack: null,
            },
            () => {
                this._triggerUpdate()
            }
        )
    };

    _selectEquipsToSlot = selectedEquipsToSlot => {
        // unset item type and item subtype:
        this.setState(
            {
                selectedEquipsToSlot,
                selectedItemSubType: null,
                selectedItemType: null,
                selectedQuest: null,
                selectedAdventurePack: null,
            },
            () => {
                this._triggerUpdate()
            }
        )
    };

    _selectQuest = selectedQuest => {
        // unset item type and item subtype:
        this.setState(
            {
                selectedQuest,
                selectedEquipsToSlot: null,
                selectedItemType: null,
                selectedItemSubType: null,
                selectedAdventurePack: null,
            },
            () => {
                this._triggerUpdate()
            }
        )
    };

    _selectAdventurePack = selectedAdventurePack => {
        // unset item type and item subtype:
        this.setState(
            {
                selectedAdventurePack,
                selectedEquipsToSlot: null,
                selectedItemType: null,
                selectedItemSubType: null,
                selectedQuest: null,
            },
            () => {
                this._triggerUpdate()
            }
        )
    };

    _toolTipHandle = (props) => {
        const { value, dragging, index, ...restProps } = props;
        return (
            <Tooltip
                prefixCls="rc-slider-tooltip"
                overlay={value}
                visible={dragging}
                placement="top"
                key={index}
            >
                <Handle value={value} {...restProps} />
            </Tooltip>
        );
    };

    _sliderAfter = (range) => {
        this._triggerUpdate()
    }

    _minMaxLevel = (range) => {
        if (this.state.CanChangeLevel === undefined || this.state.CanChangeLevel === true) {
            this.setState({
                CanChangeLevel: true
            },
                this.setState(
                    { selectedMinMaxLevel: range, CanChangeLevel: false }
                )
            )
        }
    }

    _populateSubtype = (selectedItemType) => {
        switch (selectedItemType.value) {
            case "Any":
                this.setState(
                    { subtypeOptions: null, selectedItemSubType: null }
                );
                break;
            case "Weapon":
                this.setState(
                    { subtypeOptions: weaponOptions, selectedItemSubType: null }
                );
                break;
            case "Armor":
                this.setState(
                    { subtypeOptions: armorOptions, selectedItemSubType: null }
                );
                break;
            case "Clothing":
                this.setState(
                    { subtypeOptions: null, selectedItemSubType: null }
                );
                break;
            case "Jewelry":
                this.setState(
                    { subtypeOptions: null, selectedItemSubType: null }
                );
                break;
            case "Shield":
                this.setState(
                    { subtypeOptions: shieldOptions, selectedItemSubType: null }
                );
                break;
            case "Ingredient":
                this.setState(
                    { subtypeOptions: null, selectedItemSubType: null }
                );
                break;
            case "Runearm":
                this.setState(
                    { subtypeOptions: null, selectedItemSubType: null }
                );
                break;
            case "Wands":
                this.setState(
                    { subtypeOptions: null, selectedItemSubType: null }
                );
                break;
            default:
        }
    }

    _getSearchLocation = (equipSlot) => {
        var equipslotInteger = 0;

        switch (equipSlot) {
            case "Cosmetic_Weapon1":
                equipslotInteger = 0x00000001;
                break;
            case "Head":
                equipslotInteger = 0x00000002;
                break;
            case "Eyes":
                equipslotInteger = 0x00000004;
                break;
            case "Armor":
                equipslotInteger = 0x00000008;
                break;
            case "Cloak":
                equipslotInteger = 0x00000010;
                break;
            case "Finger1":
                equipslotInteger = 0x00000020;
                break;
            case "Finger2":
                equipslotInteger = 0x00000040;
                break;
            case "Wrists":
                equipslotInteger = 0x00000080;
                break;
            case "Trinket":
                equipslotInteger = 0x00000100;
                break;
            case "Neck":
                equipslotInteger = 0x00000200;
                break;
            case "Hands":
                equipslotInteger = 0x00000400;
                break;
            case "Waist":
                equipslotInteger = 0x00000800;
                break;
            case "Feet":
                equipslotInteger = 0x00001000;
                break;
            case "Weapon1":
                equipslotInteger = 0x00002000;
                break;
            case "Weapon2":
                equipslotInteger = 0x00004000;
                break;
            case "Ammo":
                equipslotInteger = 0x00008000;
                break;
            case "Quiver":
                equipslotInteger = 0x00010000;
                break;
            case "Runearm":
                equipslotInteger = 0x00020000;
                break;
            case "PetUpgrade":
                equipslotInteger = 0x00040000;
                break;
            case "Cosmetic_Weapon2":
                equipslotInteger = 0x00080000;
                break;
            case "Cosmetic_Armor":
                equipslotInteger = 0x00200000;
                break;
            case "Cosmetic_Helmet":
                equipslotInteger = 0x00400000;
                break;
            case "PetEquipment":
                equipslotInteger = 0x00800000;
                break;
            case "Cosmetic_Cloak":
                equipslotInteger = 0x01000000;
                break;
            default:
                equipslotInteger = null;
                break;
        }

        return equipslotInteger;
    }


    _resetMinMaxSlider() {
        this.setState({
            selectedMinLevel: 0,
            selectedMaxLevel: 30
        });
    }

    render() {
        const { selectedMinMaxLevel, selectedItemType, selectedItemSubType, subtypeOptions, selectedEquipsToSlot, selectedQuest, selectedAdventurePack } = this.state;

        return (
            <div className="GearFilters">
                <div>
                    <label>Type: </label>
                    <Select
                        classNamePrefix="gf-select-items"
                        className="gf-select"
                        value={selectedItemType}
                        onChange={this._selectType}
                        options={options}
                    />
                    {selectedItemType !== null && subtypeOptions !== null ?
                        <Select
                            classNamePrefix="gf-select-items"
                            className="gf-select"
                            value={selectedItemSubType}
                            onChange={this._selectSubType}
                            options={subtypeOptions}
                        />
                        : null}
                </div>
                <div>
                    <label>Equips To Slot: </label>
                    <Select
                        classNamePrefix="gf-select-items"
                        className="gf-select"
                        value={selectedEquipsToSlot}
                        onChange={this._selectEquipsToSlot}
                        options={equipsToOptions}
                    />
                </div>
                <div>
                    <label>Quest: </label>
                    <Select
                        classNamePrefix="gf-select-items"
                        className="gf-select"
                        value={selectedQuest}
                        onChange={this._selectQuest}
                        options={this.state.Quests}
                    />
                </div>
                <div>
                    <label>Adventure Pack: </label>
                    <Select
                        classNamePrefix="gf-select-items"
                        className="gf-select"
                        value={selectedAdventurePack}
                        onChange={this._selectAdventurePack}
                        options={this.state.AdventurePacks}
                    />
                </div>
                <div>
                    <label>Min-Max Level ({selectedMinMaxLevel ? selectedMinMaxLevel[0] : null} - {selectedMinMaxLevel ? selectedMinMaxLevel[1] : null}):</label>
                    <div className="ml-slider-container">
                        <Range
                            onAfterChange={this._sliderAfter}
                            onChange={this._minMaxLevel}
                            min={0}
                            allowCross={false}
                            max={30}
                            defaultValue={[0, 30]}
                            value={selectedMinMaxLevel}
                            handle={handle}
                            marks={{ 0: 0, 5: 5, 10: 10, 15: 15, 20: 20, 25: 25, 30: 30 }} />
                    </div>
                </div>
            </div>
        );
    }
}

// PropTypes
GearFilter.propTypes = {
    ReportError: PropTypes.func.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    resetCounter: PropTypes.number.isRequired
}


export default GearFilter;
