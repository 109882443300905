// This is used to link all of the adventure packs
// requires a list (array?) of strings, as input parameter:
// ['Pack1','Pack2','An Adventure Pack 4']

import React, { Component } from 'react'
import ServerConfig from './ServerConfig'
import axios from 'axios'
import { Link } from 'react-router-dom'

export class AdventurePacks extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    // on init:
    // if we did not get a pack, look it up from API:
    // built-in: used to detect changes after initialization
    componentDidMount() {
        if (this.props.AdventurePacks !== undefined && (this.props.AdventurePacks !== null && this.props.AdventurePacks.lenth > 0)) {
            // store incoming pack:
            this.setState({ AdventurePacks: this.props.adventurePacks })
        } else {
            this._getAdventurePacks()
        }
    }

    // self contained code to pull lists from the API:
    _getAdventurePacks() {
        let remoteHost = ServerConfig.getInstance()
        let apiendpoint = remoteHost.getAPIEndpoint();
        let adventurePackList = undefined
        axios.get(
            apiendpoint + "/items/adventure-pack-list",
            { headers: { 'Content-Type': 'application/json' }, timeout: ServerConfig.longApiTimeout }
        ).then(res => {
            if (res.status !== undefined && res.status === 200) {

                if (res.data.lenth === 1)
                    this.setState({ AdventurePacks: [res.data] });
                else
                    this.setState({ AdventurePacks: res.data });

            } else {
                // THROW AERRORARSARORZ!
                this.props.ReportError("Could not connect to API: " + res.status ? res.status : "Unknown")
            }
        })
            .catch((error) => {
                this.props.ReportError(error)
            });
        return adventurePackList
    }

    render() {
        return this.state.AdventurePacks ?
            <div className="adventure-packs">
                DDO Adventure Packs:
                {this.state.AdventurePacks.map(
                    (incoming, i) =>
                        <div key={i}><Link to={"/adventure-pack/" + incoming.replace(/\s/g, "_")}>{incoming}</Link></div>
                )}
            </div>
            :
            <div>Could not locate Adventure Pack information, the list was empty!</div>
    }
}

export default AdventurePacks;