import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class AboutUs extends Component {
  render() {
    return (
      <div className="about-us">
        <div className="menu">
          <ul className="list-group list-group-horizontal">
            <li className="list-group-item"><Link to={'/adventure-packs'}>Adventure Packs</Link></li>
            <li className="list-group-item"><Link to={'/quests'}>Quests</Link></li>
            <li className="list-group-item"><Link to={'/starter-gear'}>Starter Gear</Link></li>
          </ul>
        </div>
        <p>
          The Vault of Kundarak is here to bring you tools and resources for <a href="http://www.ddo.com">Dungeons and Dragons Online</a>, an
          online game produced and operated by <a href="http://www.standingstonegames.com/">Standing Stone Games</a>.
          <li>A one-click auto-patching launcher: <a href="https://myddo.vaultofkundarak.com/download">Download here</a> from the MyDDO.VoK site</li>
          <li>An opt-in substitute for MyDDO: <a href="https://myddo.vaultofkundarak.com">MyDDO.VaultOfKundarak.com</a></li>
          <br />
          To contact us, please join <a href={"https://discord.gg/bfMZnbz"}>our discord</a>.
          <br />
          <br />
          
        </p>
      </div>
    );
  }
}

export default AboutUs;